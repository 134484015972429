import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";

const Home = () => {
  return (
    <Layout>
      <p>gatsby.jsで作成したブログです。</p>
      <p>少しずつ構成の見直しをしつつ、ブログを書いていけたらと思ってます</p>
      <StaticImage
        height={500}
        width={500}
        alt="hero image"
        src="../images/2F0B4099-8097-47D3-B863-448541F96A68.JPG"
      />
    </Layout>
  );
};
export default Home;
